<template>
  <button
      v-bind="$attrs"
      :class="['t-btn', {'outlined' : outlined}]"
      :style="width ? {'width': `${width}`} : ''"
      type="button"
      @click="$emit('click')"
  >
  <span class="t-btn__content subtitle-2">
    <slot/>
  </span>
  </button>
</template>

<script>
export default {
  name: "TBtn",
  props: {
    width: {
      type: String,
      default: undefined
    },
    outlined: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.t-btn {
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;

  font: inherit;

  transition: background-color 300ms, color 300ms;

  &:not(.outlined) {
    color: $grayscale-dark;
    background-color: $yellow-middle;
    border: none;

    &:not([disabled]):hover {
      background-color: $yellow-dark;
    }
  }

  &.outlined {
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    background-color: transparent;
    color: $white;

    &:not([disabled]):hover {
      color: $grayscale-dark;
      background-color: $white;
    }
  }

  &__content {
    color: inherit;
    display: flex;
  }

  &[disabled] {
    color: $grayscale-dark;
    background-color: $grayscale-light;
  }
}
</style>
