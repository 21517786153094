<template>
    <transition name="fade" mode="in-out">
        <actions-page
                v-if="GET_ACTIONS_CATEGORIES && GET_ACTIONS_CATEGORIES.length > 0"
                :actions-list="GET_ACTIONS_CATEGORIES"
                :action-active="actionActive"
                :loader="GET_LOADER"
                :headers="headers"
                :actions-data="GET_ACTIONS_DATA"
                :symbols="symbols"
                :active-symbol="activeSymbol"
                :total-pages="GET_ACTIONS_DATA_TOTAL_PAGES"
                :active-page="activePage"
                @switchAction="switchAction"
                @clearFilters="clearFilters"
                @changePage="changePage"
        />
    </transition>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import ActionsPage from "../components/Pages/ActionsPage";
    import Vue from "vue";
    import Toast from "../components/Toast/Toast";

    export default {
        name: "Actions",
        components: {ActionsPage},
        data() {
            return {
                actionActive: "allCat",
                activeSymbol: "allSymbols",
                activePage: 1,
                headers: [
                    { name: "Проект (Symbol)", alias: "symbols"},
                    { name: "Ранг(Rank)", alias: "ranks"},
                    { name: "Заявленное событие (Title)", alias: "title"},
                    { name: "Тип события(Category)", alias: "categoriesString"},
                    { name: "Дата события", alias: "date_event"},
                    { name: "Дата анонса", alias: "created_date"},
                    { name: "Ссылка на анонс", alias: "source"},
                    { name: "Теги", alias: "tagsString"},
                ]
            }
        },
        computed: {
            ...mapGetters(["GET_ACTIONS_CATEGORIES", "GET_LOADER", "GET_ACTIONS_DATA", "COINS", "GET_ACTIONS_DATA_TOTAL_PAGES"]),
            symbols() {
                return [{name: "Все монеты", value: "allSymbols", id: 0, symbol: "none"}, ...this.COINS.map(el => {
                    el.name = el.symbol.split("/")[0]
                    el.value = el.symbol
                    return el
                })]
            }
        },
        methods: {
            ...mapActions(["getCategories", "SET_LOADER", "getActions", "getCoins"],),
            switchAction(item, page = 1) {
                let itemId, request
                if(typeof(item) === "object") {
                    if (Object.keys(item).includes("symbol")) {
                        this.activeSymbol = item.value
                        this.actionActive = "allCat"
                        request = "coins"
                    } else {
                        request = "categories"
                        this.actionActive = item.value;
                        this.activeSymbol = "allSymbols"
                    }
                    itemId = item.id
                } else {
                    if (this.GET_ACTIONS_CATEGORIES.find(el => el.name === item)) {
                        this.actionActive = this.GET_ACTIONS_CATEGORIES.find(el => el.name === item).value
                        this.activeSymbol = "allSymbols"
                        itemId = this.GET_ACTIONS_CATEGORIES.find(el => el.name === item).id
                        request = "categories"
                    } else if (this.COINS.find(el => el.name.split("/")[0] === item)) {
                        this.activeSymbol = this.COINS.find(el => el.name.split("/")[0] === item).value
                        itemId = this.COINS.find(el => el.name.split("/")[0] === item).id
                        this.actionActive = "allCat"
                        request = "coins"
                    }
                    else {
                        this.actionActive = "allCat"
                        this.activeSymbol = "allSymbols"
                    }
                }
                if (itemId !== 0 && !itemId) {
                    const content = {
                        component: Toast,
                        props: {
                            toastProps: {
                                title: `"Такого типа событий не существует"`,
                            }
                        },
                        listeners: {
                            click: () => console.log("Clicked!"),
                        }
                    };

                    Vue.$toast(content)
                    return
                }
                this.getActions(itemId === 0 ? {page: this.activePage} : {[request]: itemId, page: page})
            },
            changePage(page) {
                this.activePage = page
                this.actionActive === "allCat" && this.activeSymbol === "allSymbols" ? this.getActions({page: this.activePage})
                    : this.actionActive !== "allCat" ? this.switchAction(this.actionActive, this.activePage)
                    : this.switchAction(this.activeSymbol, this.activePage)
            },
            clearFilters() {
                this.actionActive = "allCat"
                this.activeSymbol = "allSymbols"
                this.getActions({page: this.activePage})
            }
        },
        mounted() {
            this.getCategories()
            this.getActions({page: this.activePage})
            this.getCoins()
        }
    }
</script>