<template>
    <section :class="['dashboard', {paginated: totalPages > 1}]">
        <div class="dashboard__menu" v-if="actionsList">
            <menu-button style="min-width: 180px" :menu-list="actionsList" :active="actionActive" @switch="switcher" is-changeable-header/>
            <menu-button style="min-width: 180px" :menu-list="symbols" :active="activeSymbol" @switch="switcher" is-changeable-header/>
            <t-btn style="margin-left: 50px" width="200px" @click="$emit('clearFilters')">Сбросить фильтры</t-btn>
        </div>
        <transition name="fade" mode="out-in">
            <div v-if="!loader" class="dashboard__content">
                <table cellspacing="0">
                    <thead>
                        <tr>
                            <th v-for="header in headers" :key="header.alias">{{ header.name }}</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="actionRow in actionsData" :key="actionRow.time">
                        <td v-for="header in headers" :key="`${header.alias}-data`" :class="[{'blue': header.alias==='tagsString'}, {'tag': header.alias==='tagsString'}, {'yellow': header.alias==='source'}]">
                            <template v-if="header.alias==='tagsString'"><span v-for="tag in actionRow.tags" :key="tag" @click="$emit('switchAction', tag.replace('#', ''))">{{tag}} </span></template>
                            <span :class="[{'no-wrap': header.alias==='date_event' || header.alias==='created_date'}]" v-else-if="header.alias !== 'source'">{{ actionRow[header.alias] }}</span>
                            <a v-else target="_blank" :href="actionRow[header.alias]">Ссылка</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </transition>
        <div v-if="totalPages > 1" class="stick-pagination">
            <pagination :pages="totalPages" :active="activePage" @changePage="$emit('changePage', $event)" />
        </div>
    </section>
</template>

<script>
    import MenuButton from "../Blocks/MenuButton/MenuButton";
    import TBtn from "../Blocks/TBtn";
    import Pagination from "../Blocks/Pagination/Pagination";

    export default {
        name: "ActionsPage",
        components: {Pagination, TBtn, MenuButton},
        props: ["actionsList", "actionActive", "loader", "actionsData", "headers", "symbols", "activeSymbol", "totalPages", "activePage"],
        methods: {
            switcher(item) {
                this.$emit('switchAction', item)
            }
        }
    }
</script>

<style scoped lang="scss">
    .dashboard {
        margin-top: 147px;
        margin-bottom: 15px;
        overflow-y: scroll;
        height: calc(100% - 162px);
        &.paginated {
            margin-bottom: 77px;
            height: calc(100% - 224px);
        }
        &::-webkit-scrollbar {
            width: 0;
        }

        &__menu {
            background-color: $grayscale-dark;
            height: 40px;
            display: flex;
            align-items: center;
            padding: 0 24px;
            position: absolute;
            left: 0;
            right: 0;
            top: 83px;
            border-bottom: 1px solid $grayscale-middle-light;

            & .turning-spots-btn {
                flex: 1 1 auto;
                display: flex;
                align-items: flex-end;
                flex-direction: column;

                & .spots_btn {
                    min-width: 266px;
                    border-left: 1px solid $grayscale-middle-light;
                    padding: 8px 0 8px 15px;
                    display: flex;
                    align-items: center;
                    column-gap: 14px;
                    cursor: pointer;
                    box-sizing: border-box;

                    & svg {
                        transition: all 300ms;
                    }

                    &:hover {
                        svg {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        &__content {
            display: flex;
            column-gap: 24px;
            transition: all 300ms ease;

            & table {
                width: 100%;
                border: 2px solid $grayscale-extra-light;
                border-radius: 15px;
                overflow: hidden;
                & tr {
                    text-align: left;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.0125em;

                    color: #FFFFFF;
                    & th {
                        padding: 15px 10px;
                        background-color: $grayscale-light;
                        border-right: 2px solid $grayscale-extra-light;
                        border-bottom: 2px solid $grayscale-extra-light;
                        &:last-child {
                            border-right: 0;
                        }
                    }
                    & td {
                        padding: 14px 10px;
                        border-right: 2px solid $grayscale-extra-light;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        &:last-child {
                            border-right: 0;
                        }
                        &.blue {
                            color: $blue-middle;
                        }
                        &.yellow {
                            color: $yellow-middle;
                            & a {
                                color: $yellow-middle;
                            }
                        }
                    }
                    &:nth-child(even) {
                        & td {
                            background-color: $grayscale-middle;
                        }
                    }
                }
            }
        }
    }
    .tag {
        cursor: pointer;
    }
</style>